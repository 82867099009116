<template>
  <div>
	<div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>  
	  <div class="breadcrumb">
		<el-breadcrumb separator="/">
		  <el-breadcrumb-item :to="{ path: '/patrol' }">巡视点管理</el-breadcrumb-item>
		  <el-breadcrumb-item>巡视点详情</el-breadcrumb-item>
		</el-breadcrumb>
	  </div>
	  <div class="form-box">
		<el-form :model="form" label-width="100px">
		  <div class="form-title">基本信息</div>
		  <el-row :gutter="20">
			<el-col :span="12">
			  <el-form-item label="巡视点名称：" prop="name">
				{{ form.patrolName }}
			  </el-form-item>
			</el-col>
			<el-col :span="12">
			  <el-form-item label="巡视点编号：" prop="name">
				{{ form.patrolNumber }}
			  </el-form-item>
			</el-col>
		  </el-row>
		  <el-row :gutter="20">
			<el-col :span="12">
			  <el-form-item label="所属部门：" prop="name">
				{{ form.departmentName==null ? '无' : form.departmentName }}
			  </el-form-item>
			</el-col>
			<el-col :span="12">
			  <el-form-item label="设施位置：" prop="name">
				{{ form.facilityName }}
			  </el-form-item>
			</el-col>
		  </el-row>
		  <el-row :gutter="20">
			<el-col :span="12">
			  <el-form-item label="描述说明：" prop="name">
				{{ form.description==null ? '无' : form.description }}
			  </el-form-item>
			</el-col>
		  </el-row>
		  <div class="device-model" v-for="(item, index) in form.patrolDeviceDetails" :key="index">
			<div class="device-title" :style="!item.dShows?'margin:0;border:0;':''">
			  <span class="iconfont" :class="item.dShows?'el-icon-arrow-up':'el-icon-arrow-down'" style="font-size: 20px;color: #409EFF;" @click="toggleDevice(item)"></span> 
			  设备信息 - {{index+1}}
			</div>
			<el-collapse-transition>
			  <div v-if="item.dShows">
				<el-row :gutter="20">
				  <el-col :span="12">
					<el-form-item label="设备别名：" prop="name">
					  {{ item.alias }}
					</el-form-item>
				  </el-col>
				  <el-col :span="12">
					<el-form-item label="设备识别号：" prop="name">
					  {{ item.ein }}
					</el-form-item>
				  </el-col>
				</el-row>
				<el-row :gutter="20">
				  <el-col :span="12">
					<el-form-item label="设备名称：" prop="name">
					  {{ item.deviceName }}
					</el-form-item>
				  </el-col>
				  <el-col :span="12">
					<el-form-item label="规格参数：" prop="name">
					  {{ item.specification }}
					</el-form-item>
				  </el-col>
				</el-row>
				<el-row :gutter="20">
				  <el-col :span="12">
					<el-form-item label="所属部门：" prop="name">
					  {{ item.departmentName }}
					</el-form-item>
				  </el-col>
				  <el-col :span="12">
					<el-form-item label="设备类型：" prop="name">
					  {{ item.deviceTypeName }}
					</el-form-item>
				  </el-col>
				</el-row>
				<el-row :gutter="20">
				  <el-col :span="12">
					<el-form-item label="投入使用日期：" prop="name" label-width="130px">
					  {{ item.applyTime ? dayjs(item.applyTime).format('YYYY-MM-DD'):'' }}
					</el-form-item>
				  </el-col>
				</el-row>
				<!-- 巡检内容 -->
				<div>
				  <div class="form-title other-title">
					<span class="iconfont icon-xunjian" style="font-size: 20px;margin-right: 5px;"></span>
					<span>巡检内容</span>
				  </div>
				  <div v-for="(pItem, pIndex) in item.checkContents" :key="pIndex">
					<div v-if="pItem.taskType==0" style="margin: 20px 25px;font-size: 14px;">
					  <div v-if="pItem.checkContent">
						检查内容：
						<span style="color: #ff4757;">{{ pItem.checkContent ? pItem.checkContent : '无' }}</span>
					  </div>
					  <div v-else>此功能已关闭</div>
					</div>
				  </div>
				</div>
				<!-- 点检内容 -->
				<div>
				  <div class="form-title other-title">
					<span class="iconfont icon-tubiaozhizuomoban-133" style="font-size: 20px;margin-right: 5px;"></span>
					<span>点检内容</span>
				  </div>
				  <div v-for="(cItem, cIndex) in item.checkContents" :key="cIndex">
					<div v-if="cItem.taskType==1" style="margin: 20px 25px;font-size: 14px;">
					  <div v-if="cItem.checkContent">
						检查内容：
						<span style="color: #ff4757;">{{ cItem.checkContent ? cItem.checkContent : '无' }}</span>
					  </div>
					</div>
				  </div>
				</div>
				<!-- 润滑内容 -->
				<div class="form-title other-title">
				  <span class="iconfont icon-zhouchengrunhuayou" style="font-size: 20px;margin-right: 5px;"></span>
				  <span>润滑内容</span>
				</div>
				<div v-if="item.lubeCheckContents">
				  <div v-for="(contents, luIndex) in item.lubeCheckContents" :key="luIndex">
					<div v-if="contents.position">
					  <div style="margin-left: 25px;font-size: 14px;font-weight: bold;color: #57606f;">润滑部位 - {{luIndex+1}}</div>
					  <div style="padding: 15px;">
						<el-row :gutter="20">
						  <el-col :span="12">
							<el-form-item label="部位名称：">
							  {{ contents.position ? contents.position : '无' }}
							</el-form-item>
						  </el-col>
						  <el-col :span="12">
							<el-form-item label="润滑点数：">
							  {{ contents.points ? contents.points : '无' }}
							</el-form-item>
						  </el-col>
						</el-row>
						<el-row :gutter="20">
						  <el-col :span="6">
							<el-form-item label="润滑油品牌：">
							  {{ contents.brand ? contents.brand : '无' }}
							</el-form-item>
						  </el-col>
						  <el-col :span="6">
							<el-form-item label="润滑油型号：">
							  {{ contents.model ? contents.model : '无' }}
							</el-form-item>
						  </el-col>
						  <el-col :span="6">
							<el-form-item label="润滑油分类：">
							  {{ contents.classification ? contents.classification : '无' }}
							</el-form-item>
						  </el-col>
						  <el-col :span="6">
							<el-form-item label="润滑油等级：">
							  {{ contents.level ? contents.level : '无' }}
							</el-form-item>
						  </el-col>
						</el-row>
						<el-row :gutter="20">
						  <el-col :span="6">
							<el-form-item label="补加油用量：">
							  {{ contents.refuel ? contents.refuel : '无' }} kg
							</el-form-item>
						  </el-col>
						  <el-col :span="6">
							<el-form-item label="换底油用量：">
							  {{ contents.replace ? contents.replace : '无' }} kg
							</el-form-item>
						  </el-col>
						</el-row>
						<div style="margin: 20px 15px;font-size: 14px;" v-for="(v, k) in contents.lubeCheckMethods" :key="k">
						  {{k+1}}.润滑方法：
						  <span style="color: #ff4757;">{{ v.method ? v.method : '无' }}</span>
						</div>
					  </div>
					</div>
					<div v-else style="padding: 0px 15px 20px;font-size: 14px;">此功能已关闭</div>
				  </div>
				</div>
				<div v-else style="padding: 0px 15px 20px;font-size: 14px;">此功能已关闭</div>
			  </div>
			</el-collapse-transition>
		  </div>
		  <div class="bottom-btn">
			<div style="text-align: right;margin-right: 20px;">
			  <el-button type="primary" size="small" @click="$router.push('/patrol')">取消</el-button>
            </div>
		  </div>
		</el-form>
	  </div>
	</div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
// collapse 展开折叠
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';
import Vue from 'vue'

Vue.component(CollapseTransition.name, CollapseTransition)
export default {
  data() {
    return {
      form: {},
      row: {},
      loading: false,
	  brand: [],
      classification: [],
      level: [],
    };
  },
  methods: {
    dayjs,
	// 折叠设备
    toggleDevice(item) {
      item.dShows = !item.dShows
    },
    loadData() {
      this.loading = true;
      this.$ajax.post("patrolDetail", {
		id: this.row.id,
	  }).then((res) => {
		sessionStorage.setItem("detail", true);
		res.data.patrolDeviceDetails.forEach((v)=>{
		  v.dShows = true
		})
		this.form = res.data;
		this.loading = false;
	  }).catch(err=>{
		this.loading = false
	  })
    },
	load_brand() {
      this.$ajax.post("lubeBrand", {
		pageNum: 0,
		pageSize: 0,
	  }).then((res) => {
        this.brand = res.data
      });
    },
    load_classification() {
      this.$ajax.post("lubeClassification", {
		pageNum: 0,
		pageSize: 0,
	  }).then((res) => {
        this.classification = res.data
      })
    },
    load_level() {
      this.$ajax.post("lubeLevel", {
		pageNum: 0,
		pageSize: 0,
	  }).then((res) => {
        this.level = res.data
      })
    },
  },
  mounted() {
    if (this.$route.params.row == null) {
      this.$router.push("/patrol");
    } else {
      this.row = this.$route.params.row;
    }
	this.load_brand();
    this.load_classification();
    this.load_level();
    this.loadData();
  },
};
</script>

<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.breadcrumb {
  padding: 15px 10px;
  .el-breadcrumb {
    font-size: 18px;
  }
}
.img-box {
  img {
    display: inline-block;
    width: 200px;
    height: 200px;
    margin-left: 10px;
  }
}
.device-model {
  box-shadow: 0px 0px 10px rgba(211, 211, 211, 0.5);
  border: 1px solid #eee;
  border-radius: 10px;
  margin: 20px 0px;
  padding: 10px 20px;
  .device-title {
	padding: 10px 0px;
	margin-bottom: 20px;
	border-bottom: 1px solid #eee;
	font-weight: bold;
	span {
      cursor: pointer;
    }
  }
}
.form-title {
  font-weight: bold;
}
.other-title {
  border-left: 0;
  border-top: 1px solid #eee;
  padding: 30px 0 0;
  display: flex;
}
.form-box {
  position: relative;
  padding: 50px 50px 60px;
  .bottom-btn {
    width: 46%;
    height: 60px;
    line-height: 60px;
    position: fixed;
    bottom: 22px;
    z-index: 1;
    padding: 0 0 0 20px;
    box-sizing: border-box;
	backdrop-filter: saturate(50%) blur(8px);
    background: rgba(0, 0, 0, .1);
    border-radius: 6px;
  }
}
</style>
<style lang="less">
</style>
